.menuBar{
  padding: 20px;
  background-color: #3a5371;
  color:#ffffff;
  height: 120vh;
  cursor: pointer;
  width: 14%;
  position: fixed;
  left: 0;
}

.mainMenu{
  margin-top: 15px;
}

.menuItem{
  margin-left: 20px;
}

.menuItem p {
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
}

.content{
  margin: 20px 20px 20px 50px;
}

.page_content{
  /* min-height: calc(100vh);
  max-height: calc(100vh); */
  overflow-y: auto;
  margin-left: 15%;
}

#img {
  max-width: 100%;
  height: auto;
}


.dashboard-header{
  text-align:center;
  color:#4278b7;
  margin:30px 0;
}

.img-content{
  margin: 30px;
}

.img-text{
  margin: 50px 30px;
  font-size: 1.2rem;
}

#side-icon{
  color: #000000;
  width: 1.8rem;
  height: 1.8rem;
}